import React from "react";
import "./../BlockAdd/BlockAdd.scss";
import "./BlockAdd.scss";
import {Link} from "react-router-dom";

const DomainsBlockAdd = props => {
	return (
			<Link to="/add" className={"domains-block domains-block--add"}>
				Добавить
			</Link>
	)
};

export default DomainsBlockAdd;