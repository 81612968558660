import React, { useReducer } from 'react';
import {
	ADD_DOMAINS,
	DELETE_COUNTRY_GROUP,
	SET_COUNTRIES,
	SET_COUNTRIES_GROUPS,
	SET_COUNTRY_GROUP, UPDATE_COUNTRY_GROUP
} from './Consts';

const initialState = {
	domains: [],
	countries: [],
	countriesGroups: [],
};

function reducer(state, action) {
	switch (action.type) {
		case ADD_DOMAINS:
			const newState = JSON.parse(JSON.stringify(state));

			newState.domains = action.payload;

			return {
				...state,
				...newState
			};

		case SET_COUNTRIES: {
			return {
				...state,
				countries: action.payload,
			};
		}

		case SET_COUNTRIES_GROUPS: {
			return {
				...state,
				countriesGroups: action.payload
			};
		}

		case SET_COUNTRY_GROUP: {
			return {
				...state,
				countriesGroups: [
					...state.countriesGroups,
					action.payload,
				]
			};
		}

		case UPDATE_COUNTRY_GROUP: {
			const newCountriesGroups = state.countriesGroups;
			const findIdGroup = newCountriesGroups.findIndex(item => Number(item.id) === Number(action.payload.id));
			newCountriesGroups[findIdGroup] = action.payload;

			return {
				...state,
				countriesGroups: newCountriesGroups,
			};
		}

		case DELETE_COUNTRY_GROUP: {
			const newCountriesGroups = state.countriesGroups;
			const findIdGroup = newCountriesGroups.findIndex(item => Number(item.id) === Number(action.payload));
			newCountriesGroups.splice(findIdGroup, 1);

			return {
				...state,
				countriesGroups: newCountriesGroups,
			};
		}
		default:
			throw new Error();
	}
}

export const StateContext = React.createContext('');

export const StateProvider = props => {
	const [ state, dispatch ] = useReducer(reducer, initialState);

	return (
		<StateContext.Provider value={ { state, dispatch } }>
			{ props.children }
		</StateContext.Provider>
	);
};