import React, { useEffect, useState, Fragment, useContext } from 'react';
import './App.scss';
import Login from "./Pages/Login/Login";
import {Route, Switch} from "react-router";
import {router} from "../Router/index";
import Stub from "./Pages/Stub/Stub";
import { fetchQuery } from '../Fetch/index.js';
import { URL_GROUPS, URL_LOCALIZATION } from '../Fetch/constants.js';
import { setCountries, setCountriesGroups } from './State/Actions.js';
import { StateContext } from './State/Reducer.js';

function App() {
	const nameCookie = 'HohPush_auth';
	const [auth, setAuth] = useState(false);
	const [isFirstLoad, setIFirstLoad] = useState(true);

	const [isStub, setIsStub] = useState(false);
	const {dispatch} = useContext(StateContext);
	const [ isLoading, setIsLoading ] = useState(false);

	useEffect(() => {
		let auth = document.cookie.match('(^|;) ?' + nameCookie + '=([^;]*)(;|$)');
		if (auth) {
			setAuth(Boolean(auth[2]));
		}

	}, []);

	useEffect(() => {
		if (auth && isFirstLoad) {
			fetchQuery({ method: 'GET', url: URL_LOCALIZATION }, countries => {
				const resultCountries = [];
				for (let country in countries) {
					if (countries.hasOwnProperty(country)) {
						resultCountries.push({
							shortName: country,
							name: countries[country]
						});
					}
				}
				dispatch(setCountries(resultCountries));
			}).then(() => {
				fetchQuery({ method: 'GET', url: URL_GROUPS }, groups => {
					const groupsResult = groups.groups.map(item => {
						const groups = item.groups.split(',');
						return { ...item, groups };
					});
					dispatch(setCountriesGroups(groupsResult));
				}).catch(error => {
					console.log(error.message)
				});

				setIsLoading(true);
			}).catch(error => {
				console.log(error.message)
			});

			setIFirstLoad(false);
		}
	}, [auth, isFirstLoad]);
	return (
			<Fragment>
				<div className={`main ${auth ? '' : 'main--stub'}`}>

					{auth ?
							isLoading  && <Switch>{router.map((props, index) => <Route key={index} {...props}/>)}</Switch>
							:

							isStub ?
									<Login/>
									:
									<Stub setIsStub={setIsStub}/>
					}

				</div>
			</Fragment>
	);
}

export default App;
