import Domains from "../App/Pages/Domains/Domains";
import Domain from "../App/Pages/Domain/Domain";
import DomainAdd from "../App/Pages/DomainAdd/DomainAdd";
import Countries from '../App/Pages/Countries/Countries';

export const router = [
	{
		path: '/',
		component: Domains,
		exact: true,
	},
	{
		path: '/domain/:domainId',
		component: Domain,
	},
	{
		path: '/add',
		component: DomainAdd,
	},
	{
		path: '/countries',
		component: Countries,
	}
];