import React, {useEffect, useState, Fragment} from "react";
import "./Domain.scss";
import {fetchQuery} from "../../../Fetch";
import {URL_DOMAIN} from "../../../Fetch/constants";
import {useParams} from "react-router";
import DomainStatistics from "../../Components/Pages/Domain/Statistics/Statistics";
import DomainSettings from "../../Components/Pages/Domain/Settings/Settings";
import DomainSettingsDomain from "../../Components/Pages/Domain/SettingsDomain/SettingsDomain";
import DomainSentPush from "../../Components/Pages/Domain/SentPush/SentPush";
import DomainMailingsList from "../../Components/Pages/Domain/MailingsList/MailingsList";
import {Link} from "react-router-dom";

const Domain = props => {
	let {domainId} = useParams();
	const [domain, setDomain] = useState('');

	useEffect(() => {
		fetchQuery({method: "GET", url: `${URL_DOMAIN}/${domainId}`}, response => {
			setDomain(response);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (

			<Fragment>
				<Link to={"/"} className={"page-need-button"}>Назад</Link>
				<div className={'page-domain'}>
					{domain ?
							domain.domen.dell !== 1 ?
									<Fragment>
										<DomainStatistics
												data={{now: domain.domen.now, today: domain.domen.today, mailing: domain.mailing}}/>
										<DomainSettings data={{name: domain.domen.name, image: domain.domen.image, id: domain.domen.id}}/>
										<DomainSettingsDomain data={{link: domain.domen.link, download: domain.domen.download}}/>
										<DomainSentPush data={{image: domain.domen.image, id: domain.domen.id}}/>
										<DomainMailingsList data={domain.mailingLists}/>
									</Fragment>

									:
									<Fragment>
										<DomainStatistics
												data={{now: domain.domen.now, today: domain.domen.today, mailing: domain.mailing}}/>
										<h2>Страница удалена</h2>
										<DomainMailingsList data={domain.mailingLists}/>
									</Fragment>
							: null
					}
				</div>
			</Fragment>
	)
};

export default Domain;