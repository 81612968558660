import React from "react";
import "./Block.scss";
import ButtonLink from "../../../UI/ButtonLink/ButtonLink";

const DomainsBlock = props => {
	return (
			<div className={"domains-block"}>
				<div className="domains-block__top">
					<p className="domains-block__name">{props.data.name}</p>
				</div>

				<p className="domains-block__domain-name">{props.data.domen}</p>

				<div className="domains-block__image-wrapper">
					<img src={props.data.image} alt="" className="domains-block__image"/>
				</div>

				<ButtonLink to={`/domain/${props.data.id}`} className={"domains-block__button"}>Открыть</ButtonLink>
			</div>
	)
};

export default DomainsBlock;