import React, { useContext, useEffect, useState } from 'react';
import "./SentPush.scss";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import {PopupContext} from "../../../UI/Popup/Popup";
import SelectImage from "../../../SelectImage/SelectImage";
import {fetchQuery} from "../../../../../Fetch";
import Select from '../../../UI/Select/Select.js';
import { StateContext } from '../../../../State/Reducer.js';

const DomainSentPush = props => {
	const [title, setTitle] = useState('');
	const { state } = useContext(StateContext);
	const [groups, setGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState('all');

	useEffect(() => {
		const newGroups = state.countriesGroups.map(item => ({
			value: item.id,
			option: item.name,
		}));

		newGroups.push({
			value: 'all',
			option: 'Все страны',
		});

		setGroups(newGroups)
	}, [state.countries]);

	const handlerInputTitle = event => {
		setTitle(event.target.value);
	};

	const [description, setDescription] = useState('');

	const handlerInputDescription = event => {
		setDescription(event.target.value);
	};

	const [link, setLink] = useState('');

	const {openPopup, closePopup, setPopupBody, setPopupTitle} = useContext(PopupContext);

	const [avatar, setAvatar] = useState(props.data.image);
	const [bigImage, setBigImage] = useState('');

	const clickShowListImageAvatar = () => {
		openPopup();
		setPopupTitle('Выберите картинку для аватарки');
		setPopupBody(<SelectImage setUrl={setAvatar} closePopup={closePopup}/>);
	};

	const clickShowListImageBig = () => {
		openPopup();
		setPopupTitle('Выберите большую картинку');
		setPopupBody(<SelectImage setUrl={setBigImage} closePopup={closePopup}/>);
	};

	const handlerInputLink = event => {
		setLink(event.target.value);
	};

	const [viewSelectImageStand, setViewSelectImageStand] = useState(false);

	const handlerCheckboxSelectImageStand = event => {
		setViewSelectImageStand(event.target.checked);
	};

	const [viewSelectImageBig, setViewSelectImageBig] = useState(false);

	const handlerCheckboxSelectImageBig = event => {
		setViewSelectImageBig(event.target.checked);
	};


	const [buttonSaveName, setButtonSaveName] = useState('Отправить');
	const [buttonSaveDisabled, setButtonSaveDisabled] = useState(false);

	const sendPush = () => {
		setButtonSaveName('Отправка');
		setButtonSaveDisabled(true);

		fetchQuery({
			url: `push`,
			data: {
				id: props.data.id,
				title,
				body: description,
				link,
				idGroup: selectedGroup,
				icon: avatar,
				image: bigImage
			}
		}, response => {

			if (response.status) {
				setTimeout(() => {
					setButtonSaveName('Отправлено');

					setTimeout(() => {
						setButtonSaveName('Отправить');
						setButtonSaveDisabled(false);
					}, 2500);
				}, 2500);
			} else {
				alert(response.msg);
				setButtonSaveName('Отправить');
				setButtonSaveDisabled(false);
			}
		});
	};

	return (
			<div className="domain-sent-push">
				<p className="domain-sent-push__title">Отправить push-уведомление</p>
				<Input
						className="domain-sent-push__input"
						label="Заголовок"
						placeholder="Максимум 50 символов"
						id="domain-sent-push-input-title"
						onChange={handlerInputTitle}
						defaultValue={title}
						maxLength={50}
				/>

				<Input
						className="domain-sent-push__input"
						label="Текст уведомления"
						placeholder="До 125 символов"
						id="domain-sent-push-input-description"
						onChange={handlerInputDescription}
						defaultValue={description}
						maxLength={125}
				/>

				<Input
						className="domain-sent-push__input"
						label="Ссылка на уведомлении"
						placeholder="http://example.com/"
						id="domain-sent-push-input-link"
						onChange={handlerInputLink}
						defaultValue={link}
				/>

				<Select
					values={groups}
					handler={value => setSelectedGroup(value)}
				/>

				<div className="domain-sent-push__images">
					<div className="domain-sent-push__image-wrapper">
						<div className="domain-sent-push__image-checkbox">
							<label htmlFor="domain-sent-push-image-mini-load">Заменить стандартную картинку сайта</label>
							<input type="checkbox" id="domain-sent-push-image-mini-load" onChange={handlerCheckboxSelectImageStand}/>
						</div>
						{viewSelectImageStand ?
								<div className="domain-sent-push__image">
									<div className="domain-sent-push__image-image-wrapper">
										<img src={avatar} alt="" className="domain-sent-push__image-image"/>
									</div>

									<Button className="domain-sent-push__image-load" onClick={clickShowListImageAvatar}>Загрузить
										картинку</Button>
								</div>
								: null}
					</div>

					<div className="domain-sent-push__image-wrapper">
						<div className="domain-sent-push__image-checkbox">
							<label htmlFor="domain-sent-push-image-big-load">Загрузить большое изображение</label>
							<input type="checkbox" id="domain-sent-push-image-big-load" onChange={handlerCheckboxSelectImageBig}/>
						</div>
						{viewSelectImageBig ?
								<div className="domain-sent-push__image">
									<div className="domain-sent-push__image-image-wrapper">
										<img src={bigImage} alt="" className="domain-sent-push__image-image"/>
									</div>

									<Button className="domain-sent-push__image-load" onClick={clickShowListImageBig}>Загрузить
										картинку</Button>
								</div>
								: null}
					</div>
				</div>

				<Button
						className="domain-sent-push__button"
						onClick={sendPush}
						disabled={buttonSaveDisabled}
				>{buttonSaveName}</Button>
			</div>
	)
};

export default DomainSentPush;