import React from "react";
import "./../Button/Button.scss";
import {Link} from "react-router-dom";

const ButtonLink = props => {
	return (
			<Link
					to={props.to}
					className={`button ${props.className ? props.className : ''} ${props.color ? props.color : 'green'}`}
					disabled={props.disabled}
					onClick={props.onClick}
			>{props.children}</Link>
	)
};

export default ButtonLink;