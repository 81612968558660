import React, {useState} from "react";
import "./Style.scss";
import IconClose from "./../Icons/Close/Close";

const PopupProvider = props => {
	const [popupOpen, setPopupOpen] = useState(false);
	const [popupTitle, setPopupTitle] = useState('');
	const [popupBody, setPopupBody] = useState('');
	const body = document.querySelector('body');

	const closePopup = () => {
		if (body.classList.contains('popup-open')) {
			body.classList.remove('popup-open');
		}
		setPopupOpen(false);
	};

	const openPopup = () => {
		if (!body.classList.contains('popup-open')) {
			body.classList.add('popup-open');
		}
		setPopupOpen(true);
	};

	return (
			<PopupContext.Provider value={{
				openPopup,
				closePopup,
				setPopupBody,
				setPopupTitle
			}}>
				{props.children}

				{popupOpen ?
						<div className="popup">
							<div className="popup__inner">
								<div className="popup__top">
									<p className="popup__title">
										{popupTitle}
									</p>

									<button className="popup__close" onClick={closePopup}>
										<IconClose width="1.4rem"/>
									</button>
								</div>
								<div className="popup__body">
									{popupBody}
								</div>
							</div>
						</div>
						: null}
			</PopupContext.Provider>
	);
};

export default PopupProvider;
export const PopupContext = React.createContext('');