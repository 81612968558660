import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App/App';
// import * as serviceWorker from './serviceWorker';
import {StateProvider} from "./App/State/Reducer";
import {BrowserRouter} from "react-router-dom";
import PopupProvider from "./App/Components/UI/Popup/Popup";

ReactDOM.render(
		<React.StrictMode>
			<StateProvider>
				<BrowserRouter>
					<PopupProvider>
						<App/>
					</PopupProvider>
				</BrowserRouter>
			</StateProvider>
		</React.StrictMode>,
		document.getElementById('root')
);
// serviceWorker.unregister();
