import React, {useEffect, useState} from "react";
import './SelectImage.scss';
import {fetchQuery} from "../../../Fetch";
import {DOMAIN_API, URL_FILES_UPLOAD} from "../../../Fetch/constants";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";

const SelectImage = props => {
	const [page, setPage] = useState(0);
	const [maxPages, setMaxPages] = useState(0);
	const [images, setImages] = useState([]);

	const loadImage = (images) => {
		fetchQuery({url: `pictures?page=${page}&per_page=16`, method: "GET"}, response => {
			setMaxPages(response.TotalPages);

			const copyImages = JSON.parse(JSON.stringify(images));

			setImages([...copyImages, ...response.pictures]);
			setPage(page + 1);
		});
	};

	useEffect(() => {
		loadImage(images);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clickImage = (event, url) => {
		props.setUrl(url);
		props.closePopup();
	};

	const handlerSelectNewImage = event => {
		window.loadImage = event.target.files[0];

		const data = new FormData();
		data.append('sampleFile', event.target.files[0]);

		fetch(`${DOMAIN_API}${URL_FILES_UPLOAD}`, {
			method: 'post',
			body: data,
		}).then(response => {
			return response.json();
		}).then(response => {
			const newImages = JSON.parse(JSON.stringify(images));

			newImages.splice(0, 0, {
				url: response.link,
			});

			setImages(newImages);
		}).catch(error => {
			alert('Такая картинка уже добавлена!');
		});
	};

	const clickLoadMore = () => {
		loadImage(images);
	};

	return (
			<div className="select-image">
				<Input type="file" className="select-image__add" id="select-image-add" onChange={handlerSelectNewImage}
				       label="Добавить картинку"/>

				<ul className="select-image__list">
					{images.map((item, index) => {
						return (
								<li className="select-image__item" key={index}>
									<button className="select-image__item-image-wrapper" onClick={event => clickImage(event, item.url)}>
										<img src={item.url} alt="" className="select-image__item-image"/>
									</button>
								</li>
						)
					})}
				</ul>
				{page < maxPages ?
						<Button className="select-image__load-more" onClick={clickLoadMore}>Загрузить еще</Button>
						: null}
			</div>
	);
};

export default SelectImage;