import React from "react";
import "./Login.scss";
import {DOMAIN} from "../../../Fetch/constants";

const Login = props => {
	return (
			<div className={'page-login'}>
				<a href={`${DOMAIN}auth/vk`} className={'button green'}>Login via VK</a>
			</div>
	)
};

export default Login;