import React from "react";
import "./MailingsList.scss";

const DomainMailingsList = props => {
	return (
			<div className="domain-mailing-list">
				<p className="domain-mailing-list__title-block">Список рассылок</p>
				<ul className="domain-mailing-list__list">
					{props.data.map((item, index) => {
						return (
								<li className="domain-mailing-list__item" key={index}>
									<div className="domain-mailing-list__icon-wrapper">
										<img src={item.icon} alt="" className="domain-mailing-list__icon"/>
									</div>
									<div className="domain-mailing-list__about">
										<p className="domain-mailing-list__title">{item.title}</p>
										<p className="domain-mailing-list__description">{item.body}</p>
										<div className="domain-mailing-list__link-wrapper">
											<a href={item.link} className="domain-mailing-list__link">{item.link}</a>
										</div>
										<p className="domain-mailing-list__date">{item.DATE}</p>
										{item.country && <p className="domain-mailing-list__date">{item.country}</p>}
									</div>

									<div className="domain-mailing-list__image-wrapper">
										<img src={item.image} alt="" className="domain-mailing-list__image"/>
									</div>

									<ul className="domain-mailing-list__statistic">
										<li className="domain-mailing-list__statistic-item">
											<p className="domain-mailing-list__statistic-title red">{item.send}</p>
											<p className="domain-mailing-list__statistic-text">Отправлено</p>
										</li>
										<li className="domain-mailing-list__statistic-item">
											<p className="domain-mailing-list__statistic-title blue">{item.conversion}</p>
											<p className="domain-mailing-list__statistic-text">Переходов</p>
										</li>
									</ul>
								</li>
						)
					})}
				</ul>
			</div>
	)
};

export default DomainMailingsList;