import React, {useContext, useState} from "react";
import "./DomainAdd.scss";
import Input from "../../Components/UI/Input/Input";
import Button from "../../Components/UI/Button/Button";
import {PopupContext} from "../../Components/UI/Popup/Popup";
import SelectImage from "../../Components/SelectImage/SelectImage";
import {fetchQuery} from "../../../Fetch";
import {useHistory} from "react-router";
import {DOMAIN} from "../../../Fetch/constants";

const DomainAdd = () => {
	const history = useHistory();
	const [name, setName] = useState('');

	const handlerInputName = event => {
		setName(event.target.value);
	};
	const [domain, setDomain] = useState('');

	const handlerInputDomain = event => {
		setDomain(event.target.value);
	};

	const [image, setImage] = useState('');
	const {openPopup, closePopup, setPopupBody, setPopupTitle} = useContext(PopupContext);

	const handlerInputImage = event => {
		openPopup();
		setPopupTitle('Выбор картинки');
		setPopupBody(<SelectImage setUrl={setImage} closePopup={closePopup}/>)
	};

	const clickSave = () => {
		fetchQuery({
			url: 'domen',
			data: {
				name,
				url: domain,
				avatar: image,
			}
		}, response => {
			if (response.status) {
				history.push('/');
			} else {
				alert(response.msg)
			}
		})
	};

	return (
			<div className="domain-add-page">
				<div className="domain-add-page__inner">
					<Input label={"Имя"} defaultValue={name} onChange={handlerInputName}/>
					<Input label={"Домен"} defaultValue={domain} onChange={handlerInputDomain}/>
					<div className="domain-add-page__image-wrapper">
						<img src={`${DOMAIN}${image}`} alt="" className="domain-add-page__image"/>
					</div>
					<Button className={"domain-add-page__image-button"} onClick={handlerInputImage} alt="Нет картинки">Картинка</Button>
					<Button className={"domain-add-page__button"} onClick={clickSave}>Сохранить</Button>
				</div>
			</div>
	)
};

export default DomainAdd;