import React from 'react';
import './Select.scss';

const Select = ({ values, handler }) => {
	return (
		<select
			className="select"
			onChange={(event) => {
				handler(event.currentTarget.value)
			}}
		>
			{values.map(item => {
				if (item.value === 'all') {
					return (
						<option
							selected
							value={item.value}
							key={`${item.value}${item.option}`}
						>
							{item.option}
						</option>
					)
				} else {
					return (
						<option
							value={item.value}
							key={`${item.value}${item.option}`}
						>
							{item.option}
						</option>
					)
				}
			})}
		</select>
	)
};

export default Select;