import React, { useContext, useEffect, useState } from 'react';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import './ViewCountryGroup.scss';
import { StateContext } from '../../State/Reducer.js';

const ViewCountryGroup = ({
														buttonText,
														groupName = '',
														checkedCountries,
														handleSafe,
													}) => {
	const { state } = useContext(StateContext);
	const [ nameGroup, setNameGroup ] = useState(groupName);
	const [ countries, setCountries ] = useState([]);

	const [ search, setSearch ] = useState('');

	useEffect(() => {
		if (checkedCountries) {
			const resultCountries = state.countries.map(item => {
				return { ...item, isChecked: checkedCountries.includes(item.shortName) };
			});

			setCountries(resultCountries);
		} else {
			const resultCountries = state.countries.map(item => {
				return { ...item, isChecked: false };
			});

			setCountries(resultCountries);
		}
	}, []);

	const handleButtonSafe = () => {
		const arraySelectedCountries = countries.filter(item => item.isChecked === true);
		handleSafe(nameGroup, arraySelectedCountries);
	};

	const handleChecked = async event => {
		const isChecked = event.currentTarget.checked;
		const shortName = event.currentTarget.getAttribute('data-short-name');

		const resultCountries = countries.map(item => {
			if (item.shortName === shortName) {
				return { ...item, isChecked };
			}

			return item;
		});

		setCountries(resultCountries);
	};

	const renderListItem = item => (
		<li key={ item.shortName } className="addCountryGroup__Item">
			<input
				className="addCountryGroup__Checkbox"
				type="checkbox"
				id={ item.name }
				value={ item.name }
				checked={ item.isChecked }
				onChange={ handleChecked }
				data-short-name={ item.shortName }
			/>
			<label
				className="addCountryGroup__Label"
				htmlFor={ item.name }
			>{ item.name }</label>
		</li>
	);


	const renderWithRegular = () => (
		countries.map(item => {
			const searchValue = search.toLowerCase();
			const name = item.name.toLowerCase();
			const shortName = item.shortName.toLowerCase();
			const regular = new RegExp(searchValue);

			if (regular.test(name) || regular.test(shortName)) {
				return renderListItem(item);
			}
		})
	);

	const renderWithoutRegular = () => countries.map(item => renderListItem(item));

	return (
		<div className="addCountryGroup">
			<div className="addCountryGroup__Header">
				<Input
					className="addCountryGroup__InputAdd"
					placeholder="Название группы"
					defaultValue={ nameGroup }
					onChange={ event => setNameGroup(event.currentTarget.value) }
				/>
				<Button
					className="addCountryGroup__BtnAdd"
					onClick={ handleButtonSafe }
				>
					{ buttonText }
				</Button>
			</div>
			<Input
				className="addCountryGroup__Search"
				placeholder="Поиск"
				value={ search }
				onChange={ event => setSearch(event.currentTarget.value) }
			/>
			<ul className="addCountryGroup__List">
				{search ? renderWithRegular() : renderWithoutRegular()}
			</ul>
		</div>
	);
};

export default ViewCountryGroup;