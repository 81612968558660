import {
	ADD_DOMAINS,
	DELETE_COUNTRY_GROUP,
	SET_COUNTRIES,
	SET_COUNTRIES_GROUPS,
	SET_COUNTRY_GROUP, UPDATE_COUNTRY_GROUP
} from './Consts';

export const addDomains = data => {
	return {
		type: ADD_DOMAINS,
		payload: data,
	}
};

export const setCountries = data => {
	return {
		type: SET_COUNTRIES,
		payload: data,
	}
};

export const setCountriesGroups = data => {
	return {
		type: SET_COUNTRIES_GROUPS,
		payload: data,
	}
};

export const setCountryGroup = data => {
	return {
		type: SET_COUNTRY_GROUP,
		payload: data,
	}
}

export const updateCountryGroup = data => {
	return {
		type: UPDATE_COUNTRY_GROUP,
		payload: data,
	}
}

export const deleteCountryGroup = data => {
	return {
		type: DELETE_COUNTRY_GROUP,
		payload: data,
	}
}