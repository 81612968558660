import React from "react";
import "./SettingsDomain.scss";
import {DOMAIN} from "../../../../../Fetch/constants";

const DomainSettingsDomain = props => {
	const copyToBuffer = () => {
		navigator.clipboard.writeText(props.data.link).catch(error => alert(error.message));
	};

	return (
			<div className="domain-settings-domain">
				<div className="domain-settings-domain__block">
					<p className="domain-settings-domain__title">
						Код для сайта
					</p>
					<p className="domain-settings-domain__subtitle">
						Скопируйте и вставьте код на ваш сайт, перед закрывающим тегом head
					</p>
					<button className="domain-settings-domain__button button green" onClick={copyToBuffer}>Копировать</button>
				</div>
				<div className="domain-settings-domain__block">
					<p className="domain-settings-domain__title">Или</p>
					<p className="domain-settings-domain__subtitle">
						Не забудьте линкануть скачанный файл:
					</p>
					<p className="domain-settings-domain__subtitle">{'<' + 'script charset="UTF-8" src="workerClient.js" async' + '></script>'}</p>
					<a href={props.data.download} className="domain-settings-domain__button button green">Скачать</a>
				</div>
				<div className="domain-settings-domain__block">
					<p className="domain-settings-domain__title">Установочный файл</p>
					<p className="domain-settings-domain__subtitle">
						скопируйте файлы в каталог верхнего уровня ( root , или '/') вашего сайта.
					</p>
					<a href={`${DOMAIN}js/workerClient`} className="domain-settings-domain__button button green">Скачать</a>
				</div>
			</div>
	)
};

export default DomainSettingsDomain;