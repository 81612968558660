import React, { Fragment, useContext, useEffect, useState } from 'react';
import "./Domains.scss";
import {fetchQuery} from "../../../Fetch";
import { URL_DOMAINS, URL_GROUPS, URL_LOCALIZATION, URL_LOGOUT } from '../../../Fetch/constants';
import {StateContext} from "../../State/Reducer";
import { addDomains, setCountries, setCountriesGroups } from '../../State/Actions';
import DomainsBlock from "../../Components/Pages/Domains/Block/Block";
import DomainsBlockAdd from "../../Components/Pages/Domains/BlockAdd/BlockAdd";
import { Link } from 'react-router-dom';

const Domains = props => {
	const {state, dispatch} = useContext(StateContext);

	useEffect(() => {
	}, []);

	useEffect(() => {
		fetchQuery({method: 'GET', url: URL_DOMAINS}, response => {
			dispatch(addDomains(response.domens));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const buttonLogout = () => {
		fetchQuery({method: 'GET', url: URL_LOGOUT}, response => {
			if (response.status) {
				window.location.reload();
			}
		});
	};

	return (
		<Fragment>
				<div className="header">
					<button className={"page-need-button"} onClick={buttonLogout}>Выйти</button>
					<Link className={"page-need-button"} to="/countries">Редактор стран</Link>
				</div>
				<div className={"page-domains"}>
					<DomainsBlockAdd/>

					{state.domains ? state.domains.map(item => {
						return (
								<DomainsBlock data={item} key={item.id}/>
						)
					}) : null}
				</div>
			</Fragment>
	)
};

export default Domains;