import React, {useContext, useState} from "react";
import "./Settings.scss";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import {fetchQuery} from "../../../../../Fetch";
import {URL_DOMAIN_DEL, URL_DOMAIN_EDIT} from "../../../../../Fetch/constants";
import {PopupContext} from "../../../UI/Popup/Popup";
import SelectImage from "../../../SelectImage/SelectImage";
import {useHistory} from "react-router";

const DomainSettings = props => {
	const [name, setName] = useState(props.data.name);

	const handlerInputName = event => {
		setName(event.target.value);
	};

	const [image, setImage] = useState(props.data.image);
	const {openPopup, closePopup, setPopupBody, setPopupTitle} = useContext(PopupContext);

	const handlerInputImage = event => {
		openPopup();
		setPopupTitle('Выбор картинки');
		setPopupBody(<SelectImage setUrl={setImage} closePopup={closePopup}/>)
	};

	const [buttonSaveName, setButtonSaveName] = useState('Сохарнить');
	const [buttonSaveDisabled, setButtonSaveDisabled] = useState(false);

	const buttonSave = () => {
		setButtonSaveName('Сохраняется');
		setButtonSaveDisabled(true);

		fetchQuery({
			url: URL_DOMAIN_EDIT, method: "POST", data: {
				id: props.data.id,
				name,
				avatar: image,
			}
		}, response => {
			if (response.status) {
				setTimeout(() => {
					setButtonSaveName('Сохранилось');

					setTimeout(() => {
						setButtonSaveName('Сохранить');
						setButtonSaveDisabled(false);
					}, 2500);
				}, 2500);
			} else {
				alert(response.msg);
				setButtonSaveName('Сохранить');
			}
		})
	};

	const history = useHistory();

	const buttonDelete = () => {
		let isDelete = window.confirm("Точно удалить?");

		if (isDelete) {
			fetchQuery({
				url: URL_DOMAIN_DEL, method: "POST", data: {
					id: props.data.id,
				}
			}, response => {
				if (response.status) {
					history.push('/');
				} else {
					alert(response.msg);
				}
			});
		}
	};

	return (
			<div className="domain-settings-user">
				<Input
						className="domain-settings-user__name-input"
						type="text"
						id={"domain-settings-user-input-name"}
						label="Имя"
						defaultValue={name}
						onChange={handlerInputName}
				/>
				<div className="domain-settings-user__image-block">
					<div className="domain-settings-user__image-wrapper">
						<img src={image} alt="" className="domain-settings-user__image"/>
					</div>

					<Button className="domain-settings-user__image-change-button" onClick={handlerInputImage}>Выбрать
						картинку</Button>
				</div>
				<div className="domain-settings-user__bottom">
					<Button
							className="domain-settings-user__save"
							onClick={buttonSave}
							disabled={buttonSaveDisabled}
					>{buttonSaveName}
					</Button>
					<Button className="domain-settings-user__delete" color="red" onClick={buttonDelete}>Удалить</Button>
				</div>
			</div>
	)
};

export default DomainSettings;