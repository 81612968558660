import {
	DOMAIN_API,
} from "./constants";

export const fetchRequest = (body, url, method, content) => {
	const address = `${DOMAIN_API}${url}`;

	body = JSON.stringify(body);

	return new Request(address, {
		method,
		headers: {
			'Content-Type': content,
		},
		body,
	});
};

export const fetchQuery = async (settings, callback = () => {
}, callbackError = (error) => {
	console.log(error);
}) => {
	const
			body = settings.data,
			url = settings.url,
			method = settings.method ? settings.method : 'POST',
			content = settings.content ? settings.content : 'application/json; charset=utf-8';

	return await fetch(fetchRequest(body, url, method, content))
			.then(response => {
				return response.json();
			})
			.then(response => {
				// if (!response.status && response[ERROR_NUMBER] === 3) {
				// 	store.dispatch(deleteToken());
				// 	localStorage.removeItem('userToken');
				// 	return false;
				// }
				callback(response);
			})
			.catch(error => {
				if (error) {
					callbackError(error);
				}
			});
};