import React from "react";
import "./Stub.scss";

const Stub = props => {
	const setStub = () => {
		props.setIsStub(true);
	};

	return (
			<div className="page-stub">
				We turn coffee in to code<button onClick={setStub}>...</button>
			</div>
	)
};

export default Stub;