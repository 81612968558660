import React, { useContext } from 'react';
import Button from '../UI/Button/Button';
import './CountriesGroups.scss';
import { PopupContext } from '../UI/Popup/Popup';
import ViewCountryGroup from '../ViewCountryGroup/ViewCountryGroup';
import { StateContext } from '../../State/Reducer.js';
import { fetchQuery } from '../../../Fetch/index.js';
import { URL_GROUP_DEL, URL_GROUP_EDIT } from '../../../Fetch/constants.js';
import { deleteCountryGroup, updateCountryGroup } from '../../State/Actions.js';

const CountriesGroups = () => {
	const { state, dispatch } = useContext(StateContext);
	const { openPopup, setPopupBody, setPopupTitle, closePopup } = useContext(PopupContext);

	const onOpenPopup = (idGroup) => {
		const group = state.countriesGroups.find(item => Number(item.id) === Number(idGroup));
		setPopupBody(
			<ViewCountryGroup
				groupName={ group.name }
				checkedCountries={ group.groups }
				buttonText="Сохранить"
				handleSafe={ (nameGroup, countries) => {

					const resultCountries = countries.reduce((acc, value, index) => {
						if (index === 0) return value.shortName;
						return `${acc},${value.shortName}`;
					}, '');

					const data = {
						id: idGroup,
						name: nameGroup,
						groups: resultCountries,
					};

					fetchQuery(
						{
							url: URL_GROUP_EDIT,
							data
						},
						response => {
							if (response.status) {
								dispatch(updateCountryGroup({ ...data, groups: resultCountries.split(',') }));
								closePopup();
							} else {
								alert('ошибка сервера');
							}
						}
					);
				}}
			/>
		);
		openPopup();
	};

	const handlerEdit = event => {
		const idGroup = event.currentTarget.getAttribute('data-id');
		const groupName = event.currentTarget.getAttribute('data-name');
		onOpenPopup(idGroup);
		setPopupTitle(`Редактировать группу: ${ groupName }`);
	};

	const handlerDelete = event => {
		const idGroup = event.currentTarget.getAttribute('data-id');
		const groupName = event.currentTarget.getAttribute('data-name');
		setPopupBody(
			<div className="countryDelete">
				<Button
					className="countryDeleteBtnYes"
					onClick={closePopup}
				>
					Отменить
				</Button>
				<Button
					className="countryDeleteBtnNo"
					color="red"
					onClick={() => {
						fetchQuery(
							{
								url: URL_GROUP_DEL,
								data: {
									id: idGroup,
								}
							},
							response => {
								if (response.status) {
									dispatch(deleteCountryGroup(idGroup));
									closePopup();
								} else {
									alert('ошибка сервера');
								}
							}
						);
					}}
				>
					Удалить
				</Button>
			</div>
		);
		openPopup();
		setPopupTitle(`Удалить группу: ${ groupName }?`);
	};

	return (
		<div className="countriesGroupsListWrapper">
			<ul className="countriesGroupsList">
				{ state.countriesGroups.map(item => (
					<li key={ item.id } className="countriesGroupsList__Item">
						<div className="countriesGroupsList__ItemNameWrapper">
							<p className="countriesGroupsList__ItemId">{ item.id }</p>
							<p className="countriesGroupsList__ItemName">{ item.name }</p>
							<ul className="subCountriesList">
								{ item.groups.map(item => (
									<li className="subCountriesList__Item" key={ item }>{ item }</li>
								)) }
							</ul>
						</div>
						<Button
							className="countriesGroupsList__ItemBtnEdit"
							data-id={ item.id }
							data-name={ item.name }
							onClick={ handlerEdit }
						>
							Редактировать</Button>
						<Button
							className="countriesGroupsList__ItemBtnDelete"
							data-id={ item.id }
							data-name={ item.name }
							onClick={ handlerDelete }
							color="red"
						>
							Удалить</Button>
					</li>
				)) }
			</ul>
		</div>
	);
};

export default CountriesGroups;