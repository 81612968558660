import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchQuery } from '../../../Fetch';
import { URL_LOGOUT, URL_GROUP_ADD } from '../../../Fetch/constants';
import './Countries.scss';
import CountriesGroups from '../../Components/CountriesGroups/CountriesGroups';
import Button from '../../Components/UI/Button/Button';
import { PopupContext } from '../../Components/UI/Popup/Popup';
import ViewCountryGroup from '../../Components/ViewCountryGroup/ViewCountryGroup';
import CountriesList from '../../Components/CountriesList/CountriesList';
import { StateContext } from '../../State/Reducer.js';
import { setCountryGroup } from '../../State/Actions.js';

const buttonLogout = () => {
	fetchQuery({ method: 'GET', url: URL_LOGOUT }, response => {
		if (response.status) {
			window.location.reload();
		}
	});
};

const Countries = () => {
	const { openPopup, setPopupBody, setPopupTitle, closePopup } = useContext(PopupContext);
	const { state, dispatch } = useContext(StateContext);

	const onOpenPopup = useCallback(() => {
		openPopup();
		setPopupTitle('Добавить группу стран');
		setPopupBody(
			<ViewCountryGroup
				buttonText="Добавить"
				handleSafe={(nameGroup, countries) => {
					if (!nameGroup) {
						alert('Заполните имя группы');
						return false;
					}

					if (!countries.length) {
						alert('Выбрите хотя бы 1 страну');
						return false;
					}

					const resultCountries = countries.reduce((acc, value, index) => {
						if (index === 0) return value.shortName;
						return `${acc},${value.shortName}`;
					}, '');

					fetchQuery(
						{
							url: URL_GROUP_ADD,
							data: {
								name: nameGroup,
								groups: resultCountries,
							}
						},
						response => {
							if (response.status) {
								const groupsResult = response.groups;
								groupsResult['groups'] = groupsResult.groups.split(',');
								dispatch(setCountryGroup(groupsResult));
								closePopup();
							} else {
								alert('ошибка сервера');
							}
						}
					);
				}}
			/>
			);
	}, []);

	return (
		<div className="countries-page">
			<div className="header">
				<button className={ 'page-need-button' } onClick={ buttonLogout }>Выйти</button>
				<Link className={ 'page-need-button' } to="/">Главная</Link>
				<Button onClick={ onOpenPopup }>Добавить группу</Button>
			</div>

			<div className="countries-page-body">
				{ state.countriesGroups.length && <CountriesGroups /> }
				<CountriesList countriesList={ state.countries }/>
			</div>
		</div>
	);
};

export default Countries;