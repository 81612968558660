import React from "react";
import "./Statistics.scss";

const DomainStatistics = props => {
	return (
			<div className="domain-statistic">
				<div className="domain-statistic__block">
					<p className="domain-statistic__title green">{props.data.now}</p>
					<p className="domain-statistic__description">всего подписчиков</p>
				</div>
				<div className="domain-statistic__block">
					<p className="domain-statistic__title red">{props.data.today}</p>
					<p className="domain-statistic__description">подписчиков за сегоднгя</p>
				</div>
				<div className="domain-statistic__block">
					<p className="domain-statistic__title blue">{props.data.mailing}</p>
					<p className="domain-statistic__description">всего рассылок</p>
				</div>
			</div>
	)
};

export default DomainStatistics;