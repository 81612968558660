import React from "react";

import './CountriesList.scss';

const CountriesList = ({countriesList}) => {
	return (
			<ul className="countriesList">
				{countriesList.map(item => (
						<li
								key={item.shortName}
								className="countriesList__Item"
						>
							<p className="countriesList__ItemShortName">{item.shortName}</p>
							<p className="countriesList__ItemName">{item.name}</p>
						</li>
				))}
			</ul>
	)
};

export default CountriesList;