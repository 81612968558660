import React from "react";
import "./Button.scss";

const Button = props => {
	return (
			<button
				{...props}
				className={`button ${props.className ? props.className : ''} ${props.color ? props.color : 'green'}`}
				disabled={props.disabled}
				onClick={props.onClick}
			>{props.children}</button>
	)
};

export default Button;