import React from "react";
import "./Input.scss";

const Input = props => {
	return (
			<div className={`input ${props.className}`}>
				{props.type !== "file" ? <label htmlFor={props.id} className="input__label">{props.label}</label> : null}

				{props.type === 'textarea' ?
						<textarea
								className="input__item"
								id={props.id}
								defaultValue={props.defaultValue}
								onChange={props.onChange}
								placeholder={props.placeholder}
								maxLength={props.maxLength}
						/>
						:
						<input
								className="input__item"
								id={props.id}
								type={props.type ? props.type : 'text'}
								defaultValue={props.defaultValue}
								onChange={props.onChange}
								placeholder={props.placeholder}
								maxLength={props.maxLength}
						/>}

				{props.type !== "file" ? null : <label htmlFor={props.id} className="input__label">{props.label}</label>}
			</div>
	)
};

export default Input;