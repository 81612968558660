export const DOMAIN = 'https://sendpush.org/';
export const DOMAIN_API = 'https://sendpush.org/api/';

export const URL_DOMAINS = 'domens';
export const URL_DOMAIN = 'domen';
export const URL_FILES_UPLOAD = `upload`;

export const URL_DOMAIN_EDIT = `edit/domen`;
export const URL_DOMAIN_DEL = `del/domen`;
export const URL_LOGOUT = 'logout';

export const URL_LOCALIZATION = 'localization';
export const URL_GROUPS = 'groups';
export const URL_GROUP_ADD = 'group';
export const URL_GROUP_EDIT = 'edit/group';
export const URL_GROUP_DEL = 'del/group';
